<template>
    <div>
        <el-card v-for="(_, index, key) in seoConfig" :key="index">
            <el-tag>{{ arr[key] }}</el-tag>
            <el-form ref="form" :model="seoConfig[index]" label-width="80px">
                <el-form-item label="seo标题">
                    <el-input v-model="seoConfig[index].title" />
                </el-form-item>
                <el-form-item label="关键字">
                    <el-input v-model="seoConfig[index].keyword" type="textarea" />
                </el-form-item>
                <el-form-item label="项目描述">
                    <el-input v-model="seoConfig[index].desc" type="textarea" rows="5" />
                </el-form-item>
            </el-form>
        </el-card>
        <el-col :span="8" style="margin-top: 50px">
            <el-button type="primary" @click="subimt" :loading="!btn">提交</el-button>
        </el-col>
    </div>
</template>

<script>
export default {
    data() {
        return {
            btn: true,
            imageUrl: "",
            seoConfig: {
                home_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                article_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                activity_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                news_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                report_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                search_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                circle_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                enterprise_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                cooperation_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                topics_page: {
                    title: "",
                    keyword: "",
                    desc: "",
                },
                ranks_page: {},
            },
            arr: [
                "首页",
                "资讯栏目页",
                "活动栏目页",
                "快讯栏目页",
                "报告栏目页",
                "搜索栏目页",
                "圈子栏目页",
                "企业栏目页",
                "合作栏目页",
                "文章专题",
                "报告专题",
                "排行榜",
            ],
        };
    },
    created() {
        this.getlist();
    },
    methods: {
        async subimt() {
            this.btn = false;
            let { data: res } = await this.$http.post("/admin/SysConfig/editSeoConfig", {
                seoConfig: this.seoConfig,
            });
            if (res.errorCode == 200) {
                this.$message.success("操作成功");
            }
            this.btn = true;
        },
        async getlist() {
            let { data: res } = await this.$http.get("/admin/SysConfig/getDetail");
            if (res.errorCode == 200) {
                this.seoConfig = res.data?.seo_config || {};
            }
        },
    },
};
</script>

<style lang="less" scoped>
.el-tag--light {
    margin-bottom: 50px;
    line-height: 30px;
    width: 90px;
    height: 30px;
    text-align: center;
    font-size: 14px;
}
.is-always-shadow {
    margin-top: 10px;
}
</style>
